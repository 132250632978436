import React from "react";
import PageTemplate from "./base";
import imgHero from "../../assets/image/industries/entertainment.jpeg";

const Page = () => {
  return (
    <>
      <PageTemplate
        seoTitle="Free Scheduling Software for Entertainment Businesses | Camelo"
        metaDescription="Scheduling software for entertainment venues & entertainment businesses. Organize upcoming shifts quickly & create schedules in minutes with Camelo today."
        header="Run your entertainment business smoothly with Camelo."
        subHeader="Camelo is a better way to schedule, connect, and manage your team."
        imgUrl={imgHero}
      />
    </>
  );
};

export default Page;
